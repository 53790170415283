body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code,
pre {
  margin: 0;
  font-size: 10px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
ol,
li {
  margin: 0;
  padding-left: 0;
  list-style-position: inside;
}
li {
  margin-bottom: 8px;
}

.Calendar {
  display: grid;
  flex: 1;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}
.Calendar.disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.25;
}

.Calendar > * {
  padding-top: 34%;
  position: relative;
  font-size: 0;
  overflow: hidden;
  border-radius: 6px;
}

.Calendar > * .hidden {
  border: 1px solid transparent;
  font-size: 0;
  line-height: 0;
}

.Calendar > * > * {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.Calendar .days {
  line-height: 16px;
  border: 1px solid transparent;
  color: #999;
}

.Calendar .days * {
  font-size: 12px;
}

.Calendar .cell {
  border: 1px solid transparent;
  user-select: none;
}

.Calendar .cell:hover {
  cursor: pointer;
  border-color: #002b32;
}

.Calendar .cell.today {
  background: #eceeeb;
  color: #000;
}

.Calendar .cell.filled {
  background: #002b32;
  color: #fff;
  border-color: #002b32;
}

.Calendar .cell.disabled {
  background: rgba(0, 0, 0, 0.09);
  color: rgba(52, 64, 84, 1);
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}

.Calendar .cell.booked {
  background: rgba(0, 0, 0, 0.09);
  color: rgba(52, 64, 84, 1);
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
